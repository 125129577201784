import { ICalendarSectionContent } from 'components/Sections/Calendar/types';
import { IFormSectionContent } from 'components/Sections/Forms/types';
import { IDocumentsSectionContent } from 'components/Sections/Documents/types';
import { IGallerySectionContent } from 'components/Sections/Galleries/types';
import { IGamesSectionContent } from 'components/Sections/Games/types';
import { IImageSectionContent } from 'components/Sections/Image/types';
import { INewsSectionContent } from 'components/Sections/News/types';
import { IPlayerStatContent } from 'components/Sections/PlayerStats/types';
import { ISocialNetworkSectionContent } from 'components/Sections/SocialNetwork/types';
import { ISponsorsSectionContent } from 'components/Sections/Sponsors/types';
import { IStandingsSectionContent } from 'components/Sections/Standings/types';
import { ITextSectionContent } from 'components/Sections/Text/types';
import { IVideosContent } from 'components/Sections/Videos/types';
import { ILinkSectionContent } from 'components/Sections/Link/types';
import { WebsiteColorMode } from './Website'
import { IShopSectionContent } from 'components/Sections/Shop/types';
import { IDividerSectionContent } from 'components/Sections/Divider/types';
import { IHTMLSectionContent } from 'components/Sections/HTML/types';
import { IPostSectionContent } from 'components/Sections/Post/types';
import { IGroupSectionContent } from './Group';

export interface ISectionMode {
    type: WebsiteColorMode;
    background?: string;
    text_background?: string;
    card?: string;
    accent?: string;
    text_accent?: string;
}

export enum SectionType {
    ROSTER = 'roster',
    STATISTICS = 'statistics',
    PLAYER = 'player',
    GAME = 'game',
    EVENT = 'event',
    TRAINING = 'training',
    STANDINGS = 'standings',
    CALENDAR = 'calendar',
    SPONSORS = 'sponsors',
    RESULTS = 'results',
    GALLERIES = 'galleries',
    GALLERY = 'gallery',
    VIDEOS = 'videos',
    VIDEO = 'video',
    SINGLE_NEWS = 'single_news',
    NEWS = 'news',
    RESERVATION = 'reservation',
    SHOP = 'shop',
    HTML = 'html',
    SHARE = 'share',
    LINK = 'link',
    DOCUMENT = 'document',
    FORMS = 'forms',
    IMAGE = 'image',
    TEXT = 'text',
    DIVIDER = 'divider',
    COMBINATION = 'combination',
    POSTS = 'posts',
    POST = 'post',
    GROUP = 'groups'
}

//NOTE if some layout type is missing - please add it here
export enum SectionLayoutType {
    DEFAULT = 'default', //for all system sections type: text, image, videos, forms, documents, link, calendar, share, sponsors
    IMAGE = 'image', //for sections type: text
    LATEST = 'latest',  //for sections type: news, galleries
    SINGLE = 'single',  //for sections type: galleries, videos
    LIST = 'list',  //for sections type: sponsors
    CAROUSEL = 'carousel',  //for sections type: sponsors
    MULTIPLE = 'multiple',  //for sections type: documents
    TEAM = 'team',  //for sections type: results, statistics, standings
    LEAGUE = 'league',  //for sections type: results, statistics, standings
    SPACE = 'space', // for section divider,
    SEPARATOR = 'separator', // for section divier
    FLF = 'flf',
    FLF_LEAGUE_OVERVIEW = 'flf_league_overview',
    IMAGE_TITLE = 'image_title',
    MULTIPLE_IMAGES = 'image_multiple',
    MULTIPLE_IMAGES_WITH_TEXT = 'image_title_multiple',
    POSTS_SPOTLIGHT = 'spotlight',
    POSTS_THREE_LATES = 'three_lates',
    POSTS_IMAGE_PREVIEW = 'post_image_preview'
}

export enum ISectionBackgroundType {
    COLOR = 'color',
    IMAGE = 'image',
    VIDEO = 'video'
}

export type ISectionContent = ISponsorsSectionContent &
    IFormSectionContent &
    ICalendarSectionContent &
    INewsSectionContent &
    IPostSectionContent &
    IGallerySectionContent &
    IVideosContent &
    ITextSectionContent &
    IHTMLSectionContent &
    IPlayerStatContent &
    IStandingsSectionContent &
    ILinkSectionContent &
    IGamesSectionContent &
    IImageSectionContent &
    ISocialNetworkSectionContent &
    IDocumentsSectionContent &
    IShopSectionContent &
    IDividerSectionContent & 
    IGroupSectionContent
    //NOTE content can be different - depends on the section type

export type ISectionCustomize = {
    format?: any; //NOTE format can be different (depends on the layout type) or no format at all TODO create interfaces for format
    background?: {
        type: ISectionBackgroundType;
        url?: string;
        width?: number;
        height?: number;
    }
}; 

export enum ISectionOrigin {
    SYSTEM = 'system',
    PREDEFINED = 'predefined',
    CUSTOM = 'custom'
}

export interface ISection {
    id: number; 
    name: string;
    mode: ISectionMode;
    system_section: {
        id: number;
        type: SectionType;
    };
    position: number;
    layout: SectionLayoutType;
    content?: ISectionContent & Array<ISectionContent>;
    customize?: ISectionCustomize;
    origin: ISectionOrigin;
}